import cx from "classnames";

interface DateIconProps {
    className?: string;
}
export const ReturnIcon: React.FC<DateIconProps> = ({ className }) => {
    return (
        <svg className={cx("h-5 w-5 text-gray-400", className)}  aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 18">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 14 3-3m-3 3 3 3m-3-3h16v-3m2-7-3 3m3-3-3-3m3 3H3v3"></path>
        </svg>
    );
};