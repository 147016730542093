import dayjs from "dayjs";

export const getLocalDate = (
  date: string | Date,
  withTime: boolean = false
): string =>
  withTime
    ? `${new Date(date).toLocaleDateString()} ${new Date(
        date
      ).toLocaleTimeString()}`
    : new Date(date).toLocaleDateString();

export const formatDate = (date: string | Date): string =>
  dayjs(date).format("YYYY-MM-DD");

export const getUtcDate = (dateString: string) => {
  const localDate = dayjs(dateString);
  const utcDate = new Date(
    Date.UTC(
      localDate.year(),
      localDate.month(),
      localDate.date(),
      localDate.hour(),
      localDate.minute(),
      localDate.second(),
      localDate.millisecond()
    )
  );

  return dayjs(utcDate).toISOString();
};
