import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import Link from "components/ui/Link";
import React from "react";
import cx from "classnames";
interface Props {
  children: React.ReactNode;
  title?: string;
  subTitle?: string;
  onBack?: () => void;
  className?: string;
}

const Page: React.FC<Props> = ({ children, className }) => (
  <div className={cx("h-full w-full p-4", className)}>{children}</div>
);

export default Page;
