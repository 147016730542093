export function formatNumber(number: number): number {
  const numStr = number.toString();
  const decimalIndex = numStr.indexOf(".");

  if (decimalIndex !== -1 && numStr.length - decimalIndex - 1 >= 3) {
    return Number(number.toPrecision(2));
  }

  return number;
}
