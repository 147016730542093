import React, { ReactNode } from "react";

import { ThreeDots } from "react-loader-spinner";
import cx from "classnames";

export interface ButtonProps {
  disabled?: boolean;
  children: ReactNode;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | (() => void);
  submit?: boolean;
  loading?: boolean;
  variant?: "primary" | "secondary";
  fullWidth?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  disabled,
  children,
  className,
  onClick,
  submit,
  loading,
  variant = "primary",
  fullWidth,
}) => {
  const colors = {
    disabled: "bg-neutral-300 hover:bg-neutral-200 text-neutral-400",
    default:
      variant === "primary"
        ? "text-neutral-900 bg-brand-600 hover:bg-brand-700 active:bg-brand-800"
        : "text-neutral-900 bg-neutral-200 hover:bg-neutral-300 active:bg-neutral-400",
  };

  const canClick = !disabled && !loading;

  return (
    <button
      type={submit ? "submit" : "button"}
      onClick={onClick}
      className={cx(
        "flex h-[45px] items-center  justify-center rounded-lg font-semibold transition-colors",
        fullWidth ? "w-full" : "w-[200px]",
        !canClick ? colors.disabled : colors.default,
        !canClick && "cursor-not-allowed",
        loading && "text-transparent",
        className
      )}
      disabled={!canClick}
    >
      {loading && (
        <ThreeDots
          color="#262626"
          height={35}
          width={40}
          wrapperClass="absolute animate-fadeIn"
        />
      )}
      {children}
    </button>
  );
};

export default Button;
