import { ColumnDef, Row } from "@tanstack/react-table";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import cx from "classnames";
import { useMemo } from "react";
import { PaymentDto } from "models/Bond";
import consts from "utils/consts";
import { DateWithIcon } from "components/ui/icons/Date";
import { StatusBadge, StatusBadgeColor } from "components/ui/Badge";
import { VoucherIcon } from "components/ui/icons/Voucher";
import { ReturnIcon } from "components/ui/icons/Return";
import { ExplorerTransactionLink } from "components/ui/Link";
import Tippy from '@tippyjs/react';
import { formatNumber } from "utils/numers";

const getStatusColor = (status: string): StatusBadgeColor => {
  switch (status) {
    case "upcoming":
      return "blue";
    case "collecting":
    case "distributing":
      return "yellow";
    case "readyToPay":
        return "red";
    default:
      return "green";
  }
};

const getStatusText = (status: string): string => {
  switch (status) {
    case "readyToPay":
      return "no funds";
    default:
      return status;
  }
};

const usePaymentsTableColumns = () => {
  const columns: ColumnDef<PaymentDto>[] = useMemo(
    () => [
      {
        id: "isin",
        header: "ISIN",
        accessorKey: "bond_isin",
        size: 250,
      },
      {
        id: "bond",
        header: "Name",
        accessorKey: "bond_name",
        size: 250,
      },
      {
        id: "date",
        header: "Date",
        size: 250,
        cell: ({ row }: { row: Row<PaymentDto> }) => <DateWithIcon date={row.original.date} />
      },
      {
        id: "payout",
        header: "Value",
        accessorKey: "payout",
        size: 250,
        cell: ({ row }: { row: Row<PaymentDto> }) => 
          <span className={cx("flex", row.original.payout_sign === "+"
            ? "text-green-700" 
            : row.original.payout_sign === "-" ? "text-red-700" : "")}>
            {row.original.payout_sign}{formatNumber(row.original.payout)} {consts.defaultCurrencySymbol} 
            {row.original.hash && <ExplorerTransactionLink className="ml-2" hash={row.original.hash} />}
          </span>
      },
      {
        id: "type",
        header: "Type",
        accessorKey: "type",
        size: 250,
        cell: ({ row }: { row: Row<PaymentDto> }) => {
          return (
            <Tippy content={row.original.type}>
              <span className="inline-block">
                {row.original.type === 'coupon' ? <VoucherIcon /> : <ReturnIcon /> }
              </span>
            </Tippy>
          )
        }
      },
      {
        id: "status",
        header: "Status",
        accessorKey: "status",
        size: 250,
        cell: ({ row }: { row: Row<PaymentDto> }) => <StatusBadge color={getStatusColor(row.original.status)} text={getStatusText(row.original.status)} />,
      },
      {
        id: "expander",
        header: () => null,
        accessorKey: "",
        cell: ({ row }: { row: Row<PaymentDto> }) => {
          return row.getCanExpand() && row.original.extendable ? (
            <div onClick={row.getToggleExpandedHandler()} className={cx("flex cursor-pointer justify-end")}>
              <ChevronDownIcon className={cx("flex h-4 w-4 transition-transform", row.getIsExpanded() && "rotate-180")} />
            </div>
          ) : (
            ""
          );
        }
      }
    ],
    []
  );

  return columns;
};

export default usePaymentsTableColumns;


