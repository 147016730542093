import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";

import Link from "../Link";
import React from "react";

export interface PaginationProps {
  currentPage: number;
  totalPages: number;
  updatePage: (newPage: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  updatePage,
  totalPages,
}) => {
  return (
    <div className="flex w-full items-center justify-center gap-2 py-3">
      <Link
        onClick={() => updatePage(currentPage - 1)}
        disabled={currentPage === 1}
      >
        <ChevronLeftIcon className="h-6 p-1" />
      </Link>
      <div className="text-sm font-light">
        {currentPage} of {totalPages} pages
      </div>
      <Link
        onClick={() => updatePage(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        <ChevronRightIcon className="h-6 p-1" />
      </Link>
    </div>
  );
};

export default Pagination;
