import React, { ReactNode } from "react";

import cx from "classnames";

interface Props {
  className?: string;
  children: ReactNode;
  padded?: boolean;
  title?: ReactNode;
}

const Card: React.FC<Props> = ({ className, children, padded, title }) => {
  return (
    <div
      className={cx(
        "rounded-2xl bg-neutral-50 shadow transition-shadow hover:shadow-md",
        padded && "p-5",
        className
      )}
    >
      {title && (
        <div className="-ml-4 border-b-2 pl-8 pt-3 pb-3 text-sm font-semibold">
          {title}
        </div>
      )}
      {children}
    </div>
  );
};

export default Card;
