import Paged from "models/Paged";
import { SecurityStatus } from "models/Security";
import api from "utils/api";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";

export type PagedData<T> = {
  isPageLoading: boolean;
  isDataLoading: boolean;
  data: T[] | undefined;
  isError: boolean;
  refetch: () => void;
  pagination: {
    currentPage: number;
    updatePage: (page: number) => void;
    totalPages: number;
    totalObjects: number;
  };
};

export function usePagedData<T>(
  keys: string[],
  endpointPath: string,
  enabled = true,
  pageSize: number = 10
) {
  const [page, setPage] = useState(1);

  const { data, isLoading, isFetched, isError, refetch } = useQuery(
    [...keys, page],
    async () => {
      const { data } = await api.get<Paged<T>>(endpointPath, {
        params: { pageNumber: page, pageSize },
      });
      return data;
    },
    { keepPreviousData: true, enabled }
  );

  const result: PagedData<T> = {
    isPageLoading: !isFetched,
    isDataLoading: isLoading,
    data: data?.objectList,
    isError,
    refetch,
    pagination: {
      currentPage: page,
      updatePage: setPage,
      totalPages: data ? Math.ceil(data.totalObjects / data.pageSize) : 0,
      totalObjects: data?.totalObjects || 0,
    },
  };

  return result;
}
