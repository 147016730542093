import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import React from "react";

interface Props {}

const GeneralError: React.FC<Props> = () => {
  return (
    <div className="m-auto w-fit animate-fadeIn p-10 text-red-500">
      <ExclamationCircleIcon height={50} className="m-auto" />
      We have experienced an error.
      <div className="text-center text-xs">Please try again later.</div>
    </div>
  );
};

export default GeneralError;
