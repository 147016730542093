import Card from "components/Layout/Card";
import { Table } from "components/ui/Table";
import { BondDto, PaymentDto } from "models/Bond";
import React from "react";
import usePaymentsTableColumns from "./usePaymentsTableColumns";
import PaymentDetails from "./PaymentDetails";
import { userSelector } from "utils/redux/user/userSlice";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import api from "@/api";
import Paged from "models/Paged";
import dayjs from "dayjs";

interface Props {}

const transformMaturityPaymentStatus = (status: string): string => {
  if (status === "finished") return "paid";
  if (status === "collecting") return "collecting";
  if (status === "mature") return "readyToPay"; // no funds
  
  return "upcoming";
}

const getMaturityPaymentPayoutSign = (status: string): PaymentDto['payout_sign'] => {
  if (status === "finished") return "-";
  if (status === "collecting") return "-";
  
  return "";
}

const filterDate = (date: string): boolean => {
  const currentDate = dayjs(date).endOf('day');
  const today = dayjs().endOf('day');
  const tomorrow = dayjs().add(1, 'day').endOf('day');

  return currentDate.isBefore(today, 'day') || currentDate.isSame(today, 'day') || currentDate.isSame(tomorrow, 'day');
}

const Payments: React.FC<Props> = () => {
  const user = useSelector(userSelector);
  const userId = user!.id;

  const payments = useQuery(
    ["payments", userId], 
    async () => {
      const getCommercialBankPayments = async (): Promise<PaymentDto[]> => {
        return (await Promise.all([
          api.get<Paged<any>>(`investors/${userId}/coupons/payments?pageSize=1000000`)
            .then(x => x.data.objectList.map(x => ({
              type: "coupon",
              id: x.id,
              bond_isin: x.coupon.bond.ISIN,
              bond_name: x.coupon.bond.token.name,
              date: x.coupon.date,
              payout: x.payout_amount,
              payout_sign: "+",
              status: x.status,
              extendable: false,
              hash: x.transaction_hash
            })))
            .then(payments => payments.filter(x => x.status !== "unpaid")) as Promise<PaymentDto[]>,
          api.get<Paged<any>>(`investors/${userId}/distributions?pageSize=1000000`)
            .then(x => x.data.objectList.map(x => ({
              type: "distribution",
              id: x.id,
              bond_isin: x.bond.ISIN,
              bond_name: x.bond.token.name,
              date: x.created_at,
              payout: x.price,
              payout_sign: "-",
              status: x.status,
              extendable: false,
              hash: x.last_edit_transaction_hash
            })))
            .then(list => list.filter(x => x.status === "accepted")) as Promise<PaymentDto[]>,
          api.get<Paged<any>>(`investors/${userId}/maturity/payments?pageSize=1000000`)
            .then(x => x.data.objectList
              .filter(x => !!x.token_swap_id)
              .map(x => ({
                type: "maturity",
                id: x.id,
                bond_isin: x.bond.ISIN,
                bond_name: x.bond.token.name,
                date: x.bond.maturity_date,
                payout: x.payout_amount,
                payout_sign: "+",
                status: x.status,
                extendable: false,
                hash: x.last_edit_transaction_hash
            }))) as Promise<PaymentDto[]>
        ]))
          .flat()
          .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
      };
      const getMinisterOfFinancePayments = async (): Promise<PaymentDto[]> => {
        const bonds = (await api.get<Paged<BondDto>>(`bonds?pageSize=1000000`)).data.objectList
          .filter(bond => bond.status !== 'recorded');

        const coupons: PaymentDto[] = bonds.reduce((result: PaymentDto[], bond) => {
          const bondCouponPayments: PaymentDto[] = bond.coupons.map(coupon => ({
            type: 'coupon',
            id: coupon.id,
            bond_id: bond.id,
            bond_isin: bond.ISIN,
            bond_name: bond.token.name,
            date: coupon.date,
            created_at: coupon.created_at,
            status: coupon.status,
            payout: coupon.amount_per_bond * bond.distributed_amount,
            payout_sign: coupon.status === "paid" ? "-" : "",
            extendable: coupon.status === "upcoming" ? false : true,
          }));

          return [...result, ...bondCouponPayments];
        }, []);
        const maturities: PaymentDto[] = bonds.map(bond => ({
          type: 'maturity',
          id: bond.id,
          bond_id: bond.id,
          bond_isin: bond.ISIN,
          bond_name: bond.token.name,
          date: bond.maturity_date,
          created_at: bond.maturity_date,
          status: transformMaturityPaymentStatus(bond.status),
          payout: bond.nominal_value * bond.distributed_amount,
          payout_sign: getMaturityPaymentPayoutSign(bond.status),
          extendable: bond.status === "live" ? false : true,
        }));

        return [...coupons, ...maturities]
          .filter(payment => filterDate(payment.date))
          .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
      };

      return await (user!.type === "issuer" ? getMinisterOfFinancePayments() : getCommercialBankPayments());
    },
    { enabled: !!userId }
  );

  const columns = usePaymentsTableColumns();

  return (
    <div>
      <Card>
        {payments.data && (
          <Table
            isDataLoading={payments.isLoading}
            data={payments.data}
            columns={columns}
            renderSubComponent={({ row }) => (<PaymentDetails payment={row.original} />)}
          />
        )}
      </Card>
    </div>
  );
};

export default Payments;
