import {
  DocumentIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
import Pagination, { PaginationProps } from "./Pagination/Pagination";
import React, { useCallback } from "react";
import {
  Row,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";

import type { ColumnDef } from "@tanstack/react-table";
import GeneralError from "./GeneralError";
import Loading from "./Loading";
import cx from "classnames";
import NoData from "./NoData";

interface ReactTableProps<T extends object> {
  data?: T[];
  columns: ColumnDef<T>[];
  renderSubComponent?: (props: { row: Row<T> }) => React.ReactElement;
  isDataLoading?: boolean;
  isPageLoading?: boolean;
  isError?: boolean;
  pagination?: PaginationProps;
  onRowClick?: (row: Row<T>) => void;
  hideNoData?: boolean;
}

export const Table = <T extends object>({
  data,
  columns,
  renderSubComponent,
  isDataLoading,
  isPageLoading,
  isError,
  pagination,
  onRowClick,
  hideNoData
}: ReactTableProps<T>) => {
  const table = useReactTable({
    data: data || [],
    columns,
    getRowCanExpand: renderSubComponent ? () => true : undefined,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 0,
      size: 0,
    },
  });

  return (
    <div className="relative h-full w-full overflow-hidden rounded-2xl">
      {isPageLoading && !isDataLoading && (
        <div className="absolute mt-11 flex h-[calc(100%-92px)] w-full animate-fadeIn items-center justify-center bg-neutral-500/10 delay-200">
          <Loading />
        </div>
      )}
      <div className="w-full overflow-auto">
        <table className="min-w-full text-center">
          <thead className="border-b ">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className="whitespace-nowrap px-6 py-3 text-left text-sm font-medium text-neutral-900"
                    style={{
                      width:
                        header.getSize() !== 0 ? header.getSize() : undefined,
                    }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="relative">
            {(isDataLoading && (
              <tr className="h-44 animate-fadeIn">
                <td colSpan={table.getAllColumns().length}>
                  <Loading />
                </td>
              </tr>
            )) ||
              (isError && (
                <tr>
                  <td colSpan={table.getAllColumns().length}>
                    <GeneralError />
                  </td>
                </tr>
              )) || (
                <>
                  {data?.length === 0 && !hideNoData && (
                    <tr>
                      <td colSpan={table.getAllColumns().length}>
                        <NoData />
                      </td>
                    </tr>
                  )}

                  {table.getRowModel().rows.map((row) => (
                    <React.Fragment key={row.id}>
                      <tr
                        key={row.id}
                        onClick={onRowClick ? () => onRowClick(row) : undefined}
                        className={cx(
                          " animate-fadeIn transition-colors hover:bg-neutral-100",
                          !row.getIsExpanded() && "border-b",
                          isPageLoading && "pointer-events-none blur-sm",
                          onRowClick && "cursor-pointer"
                        )}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <td
                            className="whitespace-nowrap px-6 py-4 text-left text-sm font-light text-neutral-900"
                            key={cell.id}
                            style={{
                              width:
                                cell.column.getSize() !== 0
                                  ? cell.column.getSize()
                                  : undefined,
                            }}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        ))}
                      </tr>
                      {row.getIsExpanded() && renderSubComponent && (
                        <tr className="border-b">
                          <td colSpan={row.getVisibleCells().length}>
                            {renderSubComponent({ row })}
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
                </>
              )}
          </tbody>
        </table>
      </div>
      {pagination &&
        pagination?.totalPages > 1 &&
        !isDataLoading &&
        !isError && (
          <Pagination
            {...{
              ...pagination,
              updatePage: (newPage) => {
                table.resetExpanded();
                pagination.updatePage(newPage);
              },
            }}
          />
        )}
    </div>
  );
};
