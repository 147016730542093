import Button from "components/ui/Button";
import Loading from "components/ui/Loading";
import { usePagedData } from "components/ui/Pagination/usePagedData";
import { BondCouponPaymentDto, CouponPaymentDto, PaymentDto } from "models/Bond";
import React from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "@/api";
import { toast } from "react-hot-toast";
import consts from "utils/consts";
import { userSelector } from "utils/redux/user/userSlice";
import { useSelector } from "react-redux";
import { StatusBadge, StatusBadgeColor } from "components/ui/Badge";
import { balanceSelector } from "utils/redux/user/balanceSlice";
import { ExplorerTransactionLink } from "components/ui/Link";
import { formatNumber } from "utils/numers";

interface Props {
  payment: PaymentDto;
}

const getStatusColor = (status: string): StatusBadgeColor => {
  switch (status) {
    case "unpaid":
    case "noFunds":
      return "red";
    case "proposed":
        return "yellow";
    default:
      return "green";
  }
};

const getStatusText = (status: string): string => {
  switch (status) {
    case "accepted":
      return "paid";
    case "noFunds":
      return "unpaid";
    default:
      return status;
  }
};

const currency = consts.defaultCurrencySymbol;

const PaymentDetails: React.FC<Props> = ({ payment }) => {
  const user = useSelector(userSelector);
  const balance = useSelector(balanceSelector);
  const queryClient = useQueryClient();

  const url = payment.type === 'coupon' 
    ? `bonds/${payment.bond_id}/coupons/${payment.id}/payments`
    : `bonds/${payment.bond_id}/maturity/payments`
  const { data, isDataLoading } = usePagedData<CouponPaymentDto>(
    ["paymentDetails", payment.id],
    url,
    !!user && !!payment,
    1_000_000 // hard code
  );
    
  const { mutate: payCoupons, isLoading: isPayCouponsLoading } = useMutation(
    async (payment: PaymentDto) => {
      const resp = await api.post<BondCouponPaymentDto>(`bonds/${payment.bond_id}`, {
        action_data: { coupon_id: payment.id },
        corporate_action_type: "couponPayment"
      })

      return resp.data;
    },
    {
      onSuccess: (result: BondCouponPaymentDto) => {
        if (result.failedTxCount > 0) {
          toast.error("Failed to pay coupons. Make sure you have enough funds.");
          return;
        }
       
        toast.success("Coupons are paid.");

        queryClient.invalidateQueries({ queryKey: ["balance"] });
        queryClient.invalidateQueries({ queryKey: ["payments"] });
        queryClient.invalidateQueries({ queryKey: ["paymentDetails"] });
      },
      onError: async () => {
        toast.error("Failed to pay coupons.");
      }
    }
  );
  const { mutate: payMaturity, isLoading: isPayMaturityLoading } = useMutation(
    async (payment: PaymentDto) => {
      const resp = await api.post<boolean>(`bonds/${payment.bond_id}`, {
        action_data: {},
        corporate_action_type: "maturityPayment"
      })

      return resp.data;
    },
    {
      onSuccess: (result: boolean) => {
        if (!result) {
          toast.error("Failed to pay maturity. Make sure you have enough funds.");
          return;
        }
       
        toast.success("Maturity is paid.");

        queryClient.invalidateQueries({ queryKey: ["balance"] });
        queryClient.invalidateQueries({ queryKey: ["payments"] });
        queryClient.invalidateQueries({ queryKey: ["paymentDetails"] });
      },
      onError: async () => {
        toast.error("Failed to pay maturity.");
      }
    }
  );

  if (data?.length === 0) return null;

  return (
    <div className="p-6">
      {isDataLoading || !data ? (
        <Loading />
      ) : (
        <div className="animate-fadeIn">
          <div className="max-h-[500px] overflow-auto">
            <div className="my-6 mt-0 rounded-xl bg-zinc-200 p-3">
              <table>
                <thead>
                  <tr className="text-sm font-thin text-zinc-400">
                    <th className="w-[250px] text-left font-normal">Bank Name</th>
                    <th className="w-[250px] text-left font-normal">Bond Amount</th>
                    <th className="w-[250px] text-left font-normal">Payout Amount</th>
                    <th className="w-[250px] text-left font-normal">Payout Status</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map(couponPayment => (
                    <tr key={couponPayment.investor.id} className="">
                      <td className="w-[250px] text-left">{couponPayment.investor.name}</td>
                      <td className="w-[250px] text-left">{couponPayment.bond_amount}</td>
                      <td className="w-[250px] text-left flex">
                        {`${formatNumber(couponPayment.payout_amount)} ${currency}`} 
                        <ExplorerTransactionLink className="ml-2" hash={(couponPayment.transaction_hash || couponPayment.last_edit_transaction_hash)!} />
                      </td>
                      <td className="w-[250px] text-left">
                        <StatusBadge color={getStatusColor(couponPayment.status)} text={getStatusText(couponPayment.status)} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {data.some(couponPayment => ["unpaid", "noFunds"].includes(couponPayment.status)) && (
            <div className="flex items-center gap-6 border-t-2 border-zinc-200 py-3 pt-6">
              <div className="flex-grow rounded-xl border-[1px] border-zinc-200 p-2 text-left">
                <div className="text-xs  text-zinc-400 ">Owners</div>
                <div className="text-lg">
                  {data.reduce((acc, couponPayment) => (["unpaid", "noFunds"].includes(couponPayment.status) ? acc + 1 : acc), 0)}
                </div>
              </div>
              <div className="flex-grow rounded-xl border-[1px] border-zinc-200 p-2 text-left">
                <div className="text-xs  text-zinc-400 ">Total</div>
                <div className="text-lg">
                  {`${Number(data.reduce(
                    (acc, couponPayment) => (["unpaid", "noFunds"].includes(couponPayment.status) ? acc + couponPayment.payout_amount : acc),
                    0
                  ).toFixed(2))} ${currency}`}
                </div>
              </div>
              <div className="flex justify-end">
                {payment.type === 'coupon' && (
                  <Button loading={isPayCouponsLoading} disabled={balance < payment.payout} onClick={() => payCoupons(payment)}>
                    Pay Coupons
                  </Button>
                )}
                {payment.type === 'maturity' && (
                  <Button loading={isPayMaturityLoading} disabled={balance < payment.payout} onClick={() => payMaturity(payment)}>
                    Pay Maturity
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PaymentDetails;
