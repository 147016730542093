import React from "react";
import cx from "classnames";
import Transition from "./effects/Transition";

interface Props {
  tabs: { label: string; content: React.ReactNode }[];
  onTabClick?: (tab: string) => void;
  activeTab: string;
}

const Tabs: React.FC<Props> = ({ tabs, onTabClick, activeTab }) => {
  return (
    <div className="flex flex-col ">
      <div className="flex  text-zinc-400">
        {tabs.map((tab) => (
          <div
            key={tab.label}
            onClick={() => onTabClick?.(tab.label)}
            className={cx(
              "flex cursor-pointer items-center justify-center rounded-t-xl border-b-[2px] py-4 px-6 transition-colors hover:bg-zinc-300/30 hover:text-brand-500 active:bg-zinc-300/80 active:text-brand-400",
              activeTab === tab.label
                ? "border-b-[3px] border-b-brand-700 text-brand-700"
                : "border-b-zinc-400"
            )}
          >
            {tab.label}
          </div>
        ))}
      </div>
      <Transition transitionKey={activeTab} className="py-12">
        {tabs.find((tab) => tab.label === activeTab)?.content}
      </Transition>
    </div>
  );
};

export default Tabs;
